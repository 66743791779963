// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-co-robimy-js": () => import("./../../../src/pages/co-robimy.js" /* webpackChunkName: "component---src-pages-co-robimy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-projekcie-js": () => import("./../../../src/pages/o-projekcie.js" /* webpackChunkName: "component---src-pages-o-projekcie-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/news-details.js" /* webpackChunkName: "component---src-templates-news-details-js" */)
}

